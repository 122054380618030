import React from "react"
import styled from "styled-components"
import {
  Container,
  Heading,
  Head,
  Paragraph,
  SecondarySection,
  Section,
} from "../../components/components"
import CompanyPortfolio from "../../widgets/CompanyPortfolio/CompanyPortfolio"
import ContactForm from "../../widgets/ContactForm/ContactForm"

const Contact = () => {
  return (
    <>
      <Head pagePath="/kontakt" />
      <Section>
        <Container>
          <TopSection>
            <Heading tag="h1">
              Erzählen Sie uns mehr über Ihr Projektvorhaben.
            </Heading>
            <Paragraph>
              Wir identifizieren die Anforderungen und Chancen, um zu schauen,
              wie wir Ihnen am besten helfen können.
            </Paragraph>
          </TopSection>
        </Container>
      </Section>

      <SecondarySection></SecondarySection>
      <ContactForm />
      <Container>
        <CompanyPortfolio />
      </Container>
    </>
  )
}

export default Contact

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    text-align: center;
  }
`
